import { ready } from "../../utils/ready.js";

ready(() => {
  const iframe = document.querySelector("#delivered-email__html");
  if (!iframe) {
    return;
  }

  iframe.addEventListener("load", () => {
    iframe.style.height =
      iframe.contentWindow.document.documentElement.scrollHeight + 10 + "px";
  });

  iframe.srcdoc = atob(iframe.dataset.body);
  iframe.classList.add("loaded");
});
